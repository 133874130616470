/* src/components/Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../assets/images/home_logo.png'); /* Replace with actual path */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  .login-box {
    max-width: 400px;
  }
  