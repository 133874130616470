

@font-face {
  font-family: Goldplay;
  src: url('../public/fonts/Goldplay-Regular.ttf');
}
@font-face {
  font-family: Goldplay-bold;
  src: url('../public/fonts/Goldplay-SemiBold.ttf');
}
@font-face {
  font-family: Goldplay-bolder;
  src: url('../public/fonts/Goldplay-SemiBold.ttf');
}

*{
  margin: 0;
  padding: 0;
  font-family: Goldplay;
}